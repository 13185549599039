<template>
<div class="view-home">
  <div class="container">
    <div class="quiz-status my-4">
      <div class="card" v-show="countdown">
        <ul>
          <li class="time">
            {{ countdown }}
          </li>
          <li class="recording" v-if="quiz.proctoring">
            {{ $t('testing.recording') }}
          </li>
          <li class="interruption" v-if="quiz.proctoring">
            {{ $t('testing.interruption') }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container questions-wrap" v-if="!loading">
    <div class="question-title mb-3" v-if="quiz.questions[question_id - 1][`subject_id_${test_lang}`]">
      {{ quiz.questions[question_id - 1][`subject_id_${test_lang}`] }}
    </div>
    <div class="question-title">
      {{ $t('testing.question-number', { number: question_id }) }}
    </div>
    <div class="row mt-3">
      <div class="col-12 order-2 order-md-1 col-md-8">
        <div class="question-content ck-content">
          <div v-katex:auto v-html="question_title">
          </div>
        </div>
        <form class="row">
          <!--
            Одиночный (radio) тип вопроса
          -->
          <div v-if="answer_type === 1" class="px-3 d-flex justify-content-start align-items-start flex-column ck-content">
            <el-radio
                :disabled="checkDisabled(questions[question_id - 1])"
                v-for="answer in question_answers"
                :key="answer.id"
                v-model="selected_answer_id[0]"
                :label="answer.id"
                class="col-12 col-md-auto mx-0 single-one"
                :class="answerClass(answer.id)"
                border>
              <div v-katex:auto v-html="answer['text_' + test_lang]"></div>
            </el-radio>
          </div>
          <!--
            Множественный (checkbox) тип вопроса
          -->
          <div v-if="answer_type === 2" class="px-3 d-flex justify-content-start align-items-start flex-column ck-content">
            <el-checkbox-group v-for="answer in question_answers" :key="answer.id" v-model="selected_answer_id">
              <el-checkbox
                  :disabled="checkDisabled(questions[question_id - 1])"
                  :class="answerClass(answer.id)"
                  :label="answer.id"
                  border>
                <div v-katex:auto v-html="answer['text_' + test_lang]"></div>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <!--
            Тип вопроса: правда/ложь
          -->
          <div v-if="answer_type === 3" class="px-3 d-flex justify-content-start align-items-start flex-column ck-content">
            <el-radio
                v-for="answer in question_answers"
                :disabled="checkDisabled(questions[question_id - 1])"
                :key="answer.id"
                v-model="selected_answer_id[0]"
                class="col-12 col-md-auto mx-0 single-one"
                :class="answerClass(answer.id)"
                :label="answer.id">
                <div><p>{{ answer['text_' + test_lang ] }}</p></div>
            </el-radio>
          </div>
            <!--
              Одиночный (radio) тип вопроса
            -->
            <div v-if="answer_type === 12" class="px-3 d-flex justify-content-start align-items-start flex-column ck-content">
                <el-radio
                    :disabled="checkDisabled(questions[question_id - 1])"
                    v-for="answer in question_answers"
                    :key="answer.id"
                    v-model="selected_answer_id[0]"
                    :label="answer.id"
                    class="col-12 col-md-auto mx-0 single-one"
                    :class="answerClass(answer.id)"
                    border>
                    <div v-katex:auto v-html="answer['text_' + test_lang]"></div>
                </el-radio>
            </div>
          <div class="px-3 w-100">
            <el-alert
                v-if="comment[question_id - 1]"
                class="mt-3"
                :title="comment[question_id - 1]['message']"
                :type="comment[question_id - 1]['type']">
            </el-alert>
          </div>
        </form>
      </div>
      <div class="col-12 col-md-4 order-1">
        <div class="navigation">
          <div class="card">
            <h3>{{ $t('testing.navigation') }}</h3>
            <div class="navigation-wrap clearfix">
              <a @click="goTo(key)" class="cursor-pointer" v-for="(key) in questions.length" :key="`question_${key}`" :class="{'checked': saved_answers[key], 'active': ($route.params.id === key.toString()) }">{{ key }}</a>
            </div>
            <div class="text-center">
              <a @click="openModal" class="btn btn-danger">{{ $t('testing.finish-button') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-5">
      <div class="mx-auto">
        <a @click="prevQuestion" class="btn btn-default"><i class="el-icon-arrow-left mr-2"></i>{{ $t('testing.back-button') }}</a>
        <el-button class="btn btn-outline" v-show="!checkDisabled(questions[question_id - 1])" @click="saveAnswerEvent" :loading="btnLoading">{{ $t('testing.save-button') }}</el-button>
        <el-button v-if="!lastQuestion" @click="nextQuestion" class="btn btn-primary">{{ $t('testing.next-button') }}</el-button>
        <el-button v-if="lastQuestion" @click="openModal" class="btn btn-danger">{{ $t('testing.finish-button') }}</el-button>
      </div>
    </div>
  </div>
  <Loader v-else/>
  <b-modal id="exit-modal" hide-footer hide-header>
    <div id="modal-wrapper">
      <div id="dialog">
        <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg" alt="">
        <h3 class="mb-0">{{ modalTitle }}</h3>
        <p>{{ $t('testing.finish-modal-desc') }}</p>
        <div class="clearfix pt-3">
          <el-button @click="exit" :disabled="exitBtnLoading" class="btn btn-primary mx-2">{{ $t('testing.finish-modal-button') }}</el-button>
          <a @click="$bvModal.hide('exit-modal')" class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
        </div>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../../components/Loader';
import Vue from 'vue';
export default {
  name: "Index",
  components: {
    Loader
  },
  data(){
    return {
      quiz: {},
      question_title: '',
      answer_type: 0,
      saved_answers: [],
      right_answers: [],
      question_answers: [],
      question_id: this.$route.params.id,
      countdown: 0,
      questions: [],
      selected_answer_id: [],
      modalTitle: this.$t('testing.finish-modal-title-not-completed'),
      textarea: '',
      answers_word: [],
      quiz_token: '',
      test_lang: 'ru',
      lastQuestion: false,
      timerInterval: null,
      btnLoading: false,
      loading: true,
      windowUnloadType: window.innerWidth < 678 ? 'pagehide' : 'beforeunload',
      exitBtnLoading: false,
      remainingTimer: null,
      user_quiz_id: 0,
      comment: [],
    }
  },
  methods: {
    updateCheckedAnswers: function (){
      /*
       * Обновляем переменную saved_answers чтобы кнопки
       * отвеченных вопросов меняли цвет как в дизайне
       */
      let res = {};
      if (sessionStorage.getItem('saved-answers')){
        let savedAnswers = sessionStorage.getItem('saved-answers');
        res = JSON.parse(savedAnswers)
        this.saved_answers = res;
        /*
         * Заменяем заголовок модального окна
         * если есть ответ на последний вопрос
         */
        res.forEach((val, key) => {
          if (key === this.questions.length){
            this.modalTitle = this.$t('testing.finish-modal-title')
          }
        })
      }
    },
    async saveAnswerEvent() {
        const res = await this.saveAnswer();

        if (res) {
            let rightAnswers = [];
            if (sessionStorage.getItem('right-answers')) {
                rightAnswers = JSON.parse(sessionStorage.getItem('right-answers'));
                this.right_answers = rightAnswers
            }
            if (res.body.data) {

                if (res.body.data.correctAnswerId) {
                    rightAnswers[this.$route.params.id] = res.body.data.correctAnswerId;
                    sessionStorage.setItem('right-answers', JSON.stringify(rightAnswers));
                    this.right_answers = rightAnswers
                }

                if (res.body.data.message) {
                    this.$set(this.comment, this.question_id - 1, {
                        type: res.body.data.correct ? 'success' : 'error',
                        message: res.body.data.message ?? false,
                    });
                }
            }
        }
    },
    async goTo(id){
      if (this.$route.params.id === id.toString()) {
        return
      }
      if (id < 1 || id > this.questions.length) return
      /*
       * Обновляем переменные
       */
      // if (res.body.status === 'success') {
        /*
        * Переходим на выбранную страницу если мы уже не на ней
        */
      let answered_questions_length = 0
      for (const item of this.questions) {
        if (item.already_answered === true || item.selected.length > 0) {
          answered_questions_length++
        }
      }
      if (id === this.quiz.questions.length || answered_questions_length === this.quiz.questions.length) {
        this.lastQuestion = true
      }
      this.question_id = id.toString();
      this.question_title = this.questions[this.question_id - 1]['text_' + this.test_lang] ?? ''
      this.answer_type = this.questions[this.question_id - 1]['type'] ?? ''
      this.question_answers = this.questions[this.question_id - 1]['answers'] ?? ''
      // if (this.answer_type === 7) {
      //   this.question_title = '<p>Заполните пробелы</p>'
      // }
      this.$router.push('/quiz/question/' + id)
    // }
    },
    async saveAnswer(){
      if (this.selected_answer_id !== ''){
        const answer = (Array.isArray(this.selected_answer_id)) ? this.selected_answer_id : [this.selected_answer_id];
        const questionId = (this.questions[this.question_id - 1]['id']) ?? 0;
        let quizData = sessionStorage.getItem('quiz');
        if (quizData) {
            let data = JSON.parse(quizData);
            this.user_quiz_id = data.user_quiz_id
        }
        let params = {};
        if (answer.length){
          params = {
            user_quiz_id: this.user_quiz_id,
            question_id: questionId,
            answer_id: answer,
            answers: answer,
            type: this.answer_type,
            locale: this.test_lang
          };
        }
        if (this.textarea !== ''){
          params = {
            user_quiz_id: this.user_quiz_id,
            question_id: questionId,
            answers: [this.textarea],
            type: this.answer_type,
            locale: this.test_lang
          }
        }
        if (this.answers_word.length) {
          // console.log(this.questions[this.question_id - 1]);
          params = {
            user_quiz_id: this.user_quiz_id,
            question_id: questionId,
            answer_id: this.questions[this.question_id - 1].answers[0].id,
            answers: this.answers_word,
            type: this.answer_type,
            locale: this.test_lang
          }
        }
        if (Object.getOwnPropertyNames(params).length !== 0) {
          this.btnLoading = true
          try {
            const response = await this.$http.post(API_ROOT + '/api/quiz/save/', params)
            let savedAnswers = [];
            if (sessionStorage.getItem('saved-answers')) {
              savedAnswers = JSON.parse(sessionStorage.getItem('saved-answers'));
            }
            if (this.answer_type === 1) {
              savedAnswers[this.$route.params.id] = params.answer_id;
            }
            if (this.answer_type === 2) {
              savedAnswers[this.$route.params.id] = params.answer_id;
            }
            if (this.answer_type === 3) {
              savedAnswers[this.$route.params.id] = params.answer_id;
            }
            if (this.answer_type === 12) {
              savedAnswers[this.$route.params.id] = params.answer_id;
            }
            sessionStorage.setItem('saved-answers', JSON.stringify(savedAnswers));
            this.updateCheckedAnswers();
            return response
          } catch (error) {
            if (!navigator.onLine) {
              Vue.toastr({
                message: 'Проверьте интернет-соединение.',
                description: 'Ответ может не сохраниться.',
                type: 'warning'
              })
            }
            console.log(error);
            return error
          } finally {
            this.btnLoading = false
          }
        }
      }
    },
    prevQuestion(){
      const current = this.$route.params.id;
      if (parseInt(current) < 2){
        return;
      }
      const prevId = parseInt(current) - 1;
      this.goTo(prevId);
    },
    nextQuestion(){
      let current = this.$route.params.id;
      let nextId = parseInt(current) + 1;
      if (parseInt(current) > this.questions.length){
        return;
      }
      this.goTo(nextId);
    },
    startTimer(duration){
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        sessionStorage.setItem('timer', savedTimer);

        if (--timer < 1) {
          this.saveAnswer();
          this.exit();
          // timer = duration;
        }
      }, 1000);
    },
    checkDisabled(question){
        let res = false
        if (this.saved_answers.length) {
            this.saved_answers.forEach(answers => {
                if (answers) {
                    answers.forEach(answer => {
                        question.answers.forEach(qa => {
                            if (answer === qa.id) {
                                res = true
                            }
                        })
                    })
                }
            })
        }
        return res
    },
    answerClass(answerId){
        let className = 'incorrect'
        this.selected_answer_id.forEach(selectedAnswer => {
            this.right_answers.forEach(rightAnswers => {
                if (rightAnswers) {
                    rightAnswers.forEach(ra => {
                        if (ra === answerId) {
                            className = 'correct'
                            return className
                        }
                    })
                }
            })
            if (selectedAnswer === answerId) {
                className += ' selected-answer'
            }
            if (this.questions[this.question_id - 1].type === 12) {
                className = ''
            }
        })
        return this.quiz.show_right === 1 ? className : ''
    },
    openModal(){
      this.saveAnswer();
      this.$bvModal.show('exit-modal');
    },
    async exit(){
      try {
        let quizData = sessionStorage.getItem('quiz');
        if (quizData) {
          let data = JSON.parse(quizData);
          this.user_quiz_id = data.user_quiz_id
        }
        this.exitBtnLoading = true
        clearInterval(this.timerInterval)
        // clearInterval(this.remainingTimer)
        // const currentTime = sessionStorage.getItem('timer')
        // const { time } = JSON.parse(sessionStorage.getItem('quiz'))
        // const testFinishTime = time * 60 - currentTime
        const res = await this.$http.post(`${API_ROOT}/api/quiz/finish`, {
          user_quiz_id: this.user_quiz_id
          // remaining_time: testFinishTime
        })
        if (res.body.status !== 'success') {
          Vue.toastr({
            message: 'Error',
            description: res.body,
            type: 'error'
          })
        }
        /*
         * Если в ответе прилетели результаты, то их сохраняем в sessionStorage
         */
        if (res.body.data) {
          sessionStorage.setItem('results', JSON.stringify(res.body.data));
        }
        /*
         * Если в настройках теста включен пункт
         * "показать результаты", то переходим к страницу результатов
         */
        // this.$router.push({ name: 'results' });
        if (quizData){
          if (this.quiz.show_recommendation === 1) {
              this.$router.push({ name: 'result', params: {id: this.user_quiz_id }})
          } else {
            window.removeEventListener(this.windowUnloadType, this.beforeUnloadRequest);
            window.location.href = `${window.CABINET_ROOT}/test`;
          }
        } else {
          this.$router.push({ name: 'completed' });
        }
      } catch (e) {
        this.$bvModal.hide('exit-modal');
        Vue.toastr({
          message: 'Error',
          description: e,
          type: 'error'
        })
      } finally {
        this.exitBtnLoading = false
      }
    },
    async beforeUnloadRequest(e) {
      e = e || window.event;
      let message = 'You sure you want exit ?'
      // For IE and Firefox prior to version 4
      let left_time = sessionStorage.getItem('timer');
      if (e) {
          e.returnValue = message;
      }
      try {
        await this.$http.get(`${window.API_ROOT}/api/quiz/${this.quiz_token}/user-left`, {
          params: {
            remaining_time: left_time
          }
        })
      } catch (error) {
        console.log(error);
      }
      
      // For Safari
      return message;
    },
    sendRemaningTimer() {
      this.remainingTimer = setInterval(() => {
        this.$http.get(`${window.API_ROOT}/api/quiz/${this.quiz_token}/user-left`, {
          params: {
            remaining_time: left_time
          }
        }).catch(e => {
          console.log(e)
        })
      }, 600000)
    },
    loadSavedAnswers() {
      const mixedAnswers = []
      let answersFromStorage = []
      if (sessionStorage.getItem('saved-answers')) {
        answersFromStorage = JSON.parse(sessionStorage.getItem('saved-answers'))
      }
      mixedAnswers[0] = null
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].type === 6 || this.questions[i].type === 7) {
          mixedAnswers[i + 1] = this.questions[i].user_answer_words ? this.questions[i].user_answer_words : answersFromStorage[i + 1] ? answersFromStorage[i + 1] : null
        } else if (this.questions[i].user_answer) {
          mixedAnswers[i + 1] = this.questions[i].user_answer ? this.questions[i].user_answer : answersFromStorage[i + 1] ? answersFromStorage[i + 1] : null
        } else {
          mixedAnswers[i + 1] = this.questions[i].selected.length ? this.questions[i].selected : answersFromStorage[i + 1] ? answersFromStorage[i + 1] : null
        }
      }
      sessionStorage.setItem('saved-answers', JSON.stringify(mixedAnswers))
    },
    eventsOnCustomInputs() {
      setTimeout(() => {
        const inputs = [...this.$refs.strWithInputs.querySelectorAll('input')]
        inputs.map(input => {
          const index = +input.dataset.model
          input.value = this.answers_word[index] ? this.answers_word[index] : ''
          input.addEventListener('input', (e) => {
            this.answers_word[index] = e.target.value
          })
        })
      }, 300)
    },
    eventsOnCustomSelects() {
      setTimeout(() => {
        const selects = [...this.$refs.strWithSelect.querySelectorAll('select')]
        selects.map(select => {
          const index = +select.dataset.model
          const value = this.answers_word[index] ? this.answers_word[index] : ''
          const options = [...select.querySelectorAll('option')]
          options.map(option => {
            option.innerText === value ? option.selected = true : null
          })
          select.addEventListener('change', (e) => {
            this.answers_word[index] = e.target.value
          })
        })
      }, 300)
    }
  },
  watch:{
    $route (to){
      /*
       * Сохраняем выбранных ответов, чтобы их выделить когда юзер вернулся обратно
       */
      this.selected_answer_id = [];
      this.textarea = '';
      this.answers_word = []
      if (sessionStorage.getItem('saved-answers')){
        let savedAnswers = JSON.parse(sessionStorage.getItem('saved-answers'));
        if (savedAnswers[to.params.id]) {
          switch (this.answer_type){
            case 1:
              this.selected_answer_id = savedAnswers[to.params.id][0];
              break;
            case 2:
              this.selected_answer_id = savedAnswers[to.params.id];
              break;
            case 3:
              this.selected_answer_id = savedAnswers[to.params.id][0];
              break;
            case 12:
              this.selected_answer_id = savedAnswers[to.params.id][0];
              break;
          }
        }
      }
      +this.$route.params.id === this.questions.length ? (this.lastQuestion = true) : (this.lastQuestion = false)
      if (this.questions[this.question_id - 1]) {
        // this.selected_answer_id = this.questions[this.question_id - 1]['selected'] ?? [];
        // if (!this.questions[this.question_id - 1]['selected']) {
          this.selected_answer_id = this.saved_answers[this.question_id] ?? []
        // }
      }
    },
    question_id() {
      // if (this.answer_type === 6) {
      //   this.eventsOnCustomInputs()
      // }
      // if (this.answer_type === 7) {
      //   this.eventsOnCustomSelects()
      // }
    }
  },
  beforeCreate() {
    if (sessionStorage.getItem('quiz')){
      let quiz = sessionStorage.getItem('quiz');
      quiz = JSON.parse(quiz);
      if (sessionStorage.getItem('identification')){
        let identification = sessionStorage.getItem('identification');
        identification = JSON.parse(identification);
        if (quiz.proctoring && !identification){
          window.location.href = '/identification';
        }
      }
    } else {
      this.$router.push({ name: 'not-found' })
    }
  },
  async mounted() {
    this.selected_answer_id = [];
    if (sessionStorage.getItem('quiz')){
      if (sessionStorage.getItem('test_lang')){
        this.test_lang = sessionStorage.getItem('test_lang');
        this.$i18n.locale = this.test_lang;
      }
      const quiz = JSON.parse(sessionStorage.getItem('quiz'));
      if (this.$route.params.id !== '1') {
        this.$router.push('/quiz/question/1')
      }
      this.loading = false
      this.quiz = quiz;
      this.questions = quiz.questions;
      // let timer = 0;

      // this.countdown = ('0' + quiz.time).slice(-2) + ':00';
      // this.countdown = ('');

      // timer = quiz.remaining_time;
      // if (timer < 1) {
      //   this.exit()
      //   return
      // }
      // this.startTimer(timer);
      this.question_title = this.questions[this.question_id - 1]['text_' + this.test_lang] ?? ''
      this.answer_type = this.questions[this.question_id - 1]['type'] ?? '';
      this.question_answers = this.questions[this.question_id - 1]['answers'] ?? '';
      this.selected_answer_id = this.questions[this.question_id - 1]['selected'] ?? [];
      this.loadSavedAnswers()
      this.updateCheckedAnswers();
      // if (this.answer_type === 6) {
      //   if (sessionStorage.getItem('saved-answers')){
      //     let savedAnswers = JSON.parse(sessionStorage.getItem('saved-answers'));
      //     if (savedAnswers[this.$route.params.id]) {
      //      this.answers_word = savedAnswers[this.$route.params.id]
      //     }
      //   }
      //   this.eventsOnCustomInputs()
      // }
      // if (this.answer_type === 7) {
      //   this.question_title = '<p>Заполните пробелы</p>'
      //   if (sessionStorage.getItem('saved-answers')){
      //     let savedAnswers = JSON.parse(sessionStorage.getItem('saved-answers'));
      //     if (savedAnswers[this.$route.params.id]) {
      //      this.answers_word = savedAnswers[this.$route.params.id]
      //     }
      //   }
      //   this.eventsOnCustomSelects()
      // }
      if (!this.questions[this.question_id - 1]['selected']) {
        this.selected_answer_id = this.saved_answers[this.question_id]
      }
    }
    if (sessionStorage.getItem('quiz-token')){
      this.quiz_token = sessionStorage.getItem('quiz-token');
    }
    +this.$route.params.id === this.questions.length ? (this.lastQuestion = true) : (this.lastQuestion = false)
    window.addEventListener(this.windowUnloadType, this.beforeUnloadRequest)
    // this.sendRemaningTimer()
  },
  beforeDestroy() {
    window.removeEventListener(this.windowUnloadType, this.beforeUnloadRequest)
  },
}
</script>

<style>
.el-button.btn.btn-outline{
    border: 1px solid #473f95;
    padding: 9px 29px;
    color: #473f95;
}
.el-checkbox.is-disabled.incorrect.selected-answer .el-checkbox__label,
.el-radio.is-disabled.incorrect.selected-answer .el-radio__label{
    color: #f56c6c;
}
.el-checkbox.is-disabled.correct .el-checkbox__label,
.el-radio.is-disabled.correct .el-radio__label{
    color: #67c23a;
}
.el-radio.is-disabled.correct .el-radio__inner,
.el-checkbox.is-disabled.correct .el-checkbox__inner{
    background: #f0f9eb;
    border-color: #c2e7b0;
}
.el-radio.is-disabled.correct .el-radio__inner:after{
    background-color: #67c23a;
}
.el-checkbox.is-disabled.correct .el-checkbox__inner:after{
    border-color: #67c23a;
}
.el-radio.is-disabled.incorrect.selected-answer .el-radio__inner,
.el-checkbox.is-disabled.incorrect.selected-answer .el-checkbox__inner{
    background: #fef0f0;
    border-color: #fbc4c4;
}
.el-radio.is-disabled.incorrect.selected-answer .el-radio__inner:after{
    background-color: #f56c6c;
}
.el-checkbox.is-disabled.incorrect.selected-answer .el-checkbox__inner:after{
    border-color: #f56c6c;
}
.modal .modal-body {
    padding: 2rem;
}

.modal #dialog button.close {
    border-radius: 44px;
    width: 44px;
    height: 44px;
    background-color: #B5B5B5;
    background-image: url('/images/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: -1rem;
    top: -1rem;
    opacity: 1;
    transition: all 0.2s ease;
}

.modal #dialog button.close:hover {
    background-color: #8a8a8a;
    transition: all 0.2s ease;
}

.modal #dialog button.close:focus,
.modal #dialog button.close:active {
    outline: none;
}

.modal {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    text-align: center;
    box-sizing: border-box;
}

.modal #dialog {
    font-family: 'Roboto', sans-serif;
    display: inline-block;
    position: relative;
    width: 100%;
}

.modal #dialog h3 {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 10px;
    padding: 0;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
}

.modal #dialog span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.modal #dialog .display-block {
    display: block;
}

.modal #dialog p {
    margin-top: 20px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.modal #dialog #form {
    max-width: 300px;
    margin: 25px auto 0;
}

.modal #dialog #form button {
    margin: 30px 0 50px;
    width: 100%;
    padding: 6px;
    background-color: #b85fc6;
    border: none;
    text-transform: uppercase;
}

.modal #dialog div {
    position: relative;
    z-index: 1;
}

.modal #dialog img {
    position: absolute;
    bottom: -70px;
    right: -63px;
}

.modal #dialog img.warning-icon {
    position: initial;
}
.modal-content{
    border-radius: 8px;
}
.modal #dialog .modal-warning-img{
    position: initial;
}
.btn {
    border-radius: 5px;
    border: none;
}
.custom-answer-select {
  padding: 10px 35px 10px 15px;
  border: 1px solid #dfdfdf;
  appearance: none;
  border-radius: 6px;
  background-image: url('/images/down-filled-triangular-arrow.svg');
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: 90% 50%;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-pointer:hover{
  text-decoration: none;
}
p{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}
.question-content p {
  font-size: 20px;
}
.el-radio__label p,
.el-checkbox__label p{
  margin: 0;
  font-size: 18px;
}
.el-radio__label{
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  white-space: normal;
}
.el-checkbox__input,
.el-radio__input{
  display: block;
  float: left;
  margin: 3px 0 0;
}
.el-radio.is-bordered{
  padding-bottom: 12px;
}
.row {
  direction: initial;
}
.el-checkbox.is-bordered,
.el-radio.is-bordered{
  max-height: fit-content;
  height: initial;
  min-height: inherit;
  border: transparent;
}
@supports (-webkit-touch-callout: none) {
    .el-checkbox.is-bordered,
    .el-radio.is-bordered {
      max-height: fit-content;
      min-height: 40px;
    }
    .row {
      display: initial;
    }
}
.el-checkbox.is-checked,
.el-radio.is-checked{
  background: #fff;
  border: transparent;
}
</style>
